import './App.css';
import './components/Login.css';
import './components/Register.css';
import './components/Dashboard.css';
// Import any other CSS files you have
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';

// Login Component
const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', { email, password });
      onLogin(response.data.userId);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
      <button type="submit">Login</button>
    </form>
  );
};

// Register Component
const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/register', { email, password });
      alert('Registration successful! Please login.');
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
      <button type="submit">Register</button>
    </form>
  );
};

// Dashboard Component
const Dashboard = ({ onLogout }) => {
  const [emails, setEmails] = useState([]);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get('/api/emails', {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        setEmails(response.data.value);
      } catch (error) {
        console.error('Failed to fetch emails:', error);
      }
    };

    if (accessToken) {
      fetchEmails();
    }
  }, [accessToken]);

  const handleOffice365Login = async () => {
    try {
      const response = await axios.get('/api/auth/office365');
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Failed to initiate Office 365 login:', error);
    }
  };

  return (
    <div>
      <h1>Dashboard</h1>
      {!accessToken && <button onClick={handleOffice365Login}>Connect Office 365</button>}
      {accessToken && (
        <div>
          <h2>Your Emails:</h2>
          <ul>
            {emails.map((email) => (
              <li key={email.id}>{email.subject}</li>
            ))}
          </ul>
        </div>
      )}
      <button onClick={onLogout}>Logout</button>
    </div>
  );
};

// Main App Component
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = (userId) => {
    localStorage.setItem('userId', userId);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />} />
          <Route path="/register" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Register />} />
          <Route path="/dashboard" element={isLoggedIn ? <Dashboard onLogout={handleLogout} /> : <Navigate to="/login" />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/" element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} />} />
        </Routes>
      </div>
    </Router>
  );
}

// AuthCallback Component to handle Office 365 authentication callback
const AuthCallback = () => {
  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (code && state) {
        try {
          const response = await axios.post('/api/auth/office365/callback', { code, state });
          localStorage.setItem('accessToken', response.data.accessToken);
          window.location.href = '/dashboard';
        } catch (error) {
          console.error('Failed to exchange code for token:', error);
          window.location.href = '/login';
        }
      } else {
        window.location.href = '/login';
      }
    };

    handleCallback();
  }, []);

  return <div>Processing authentication...</div>;
};

export default App;
